import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import amplitude from 'amplitude-js';
import config from './config.json';

import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import "primereact/resources/primereact.min.css";  
import "primeicons/primeicons.css";  
import DashboardScreen from './screens/dashboard/DashboardScreen';
import LoginScreen from './screens/login/LoginScreen';
import SignupScreen from './screens/sign-up/SignupScreen';
import TrackerScreen from './screens/tracker/TrackerScreen';
import { AuthorizationService } from './services/AuthorizationService';
import { ReferralsHome } from './screens/referrals/ReferralsHome';

function App() {

  amplitude.getInstance().init(config.AMPLITUDE_KEY);

  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {

    const initialLoad = async () => {
      
      try {
        amplitude.getInstance().logEvent("App Loaded");

        const authorizationService = new AuthorizationService(); 
        console.log("authorizationService.isLoggedIn()", authorizationService.isLoggedIn())
        if(authorizationService.isLoggedIn()){
          const user = await authorizationService.reload();
          console.log("user", user);
          setLoggedInUser(user);
          console.log("setLoggedInUser")
          setIsLoggedIn(authorizationService.isLoggedIn());        
          console.log("authorizationService.isLoggedIn()", authorizationService.isLoggedIn())
        }
        setLoaded(true)
        console.log("loaded")
      } catch (error) {
        window.alert(error)
      }
      
    }
    
    initialLoad();

  }, []);

  const onAuthenticatedUserUpdate = (user) => {
    const authorizationService = new AuthorizationService(); 
    authorizationService.setLoggedInUser(user);
    setIsLoggedIn(user.active);
    setLoggedInUser(authorizationService.loggedInUser);
}

  const onAuthenticated = (user) => {
    amplitude.getInstance().setUserProperties(user);
    amplitude.getInstance().logEvent("Signin Successfull");
    const authorizationService = new AuthorizationService(); 
    authorizationService.setLoggedInUser(user);
    
    setIsLoggedIn(user.active);
    setLoggedInUser(authorizationService.loggedInUser);

    if(user.active){
      navigate("/");
    }
    else {
        //this.props.history.push("/add-your-friends");
        //this.props.history.push("/select-sales-person");
        alert("Under construction");
    }
  }
  
  return (
    <div className="App">
      { loaded && !isLoggedIn && <>
        <Routes>
          <Route path="/" element={<LoginScreen onAuthenticated={onAuthenticated}/>}/>
          <Route path="/sign-up" element={<SignupScreen onSignedUp={onAuthenticated}/>}/>
          <Route path="*" element={<LoginScreen onAuthenticated={onAuthenticated}/>}/>
        </Routes>
        </>
        
      }
      { loaded && isLoggedIn &&
        <Routes>
          <Route path="/" element={<DashboardScreen user={loggedInUser} onUserUpdate={onAuthenticatedUserUpdate}/>}/>
          <Route path="/tracker" element={<TrackerScreen user={loggedInUser}/>}/>
          <Route path="/referrals" element={<ReferralsHome user={loggedInUser} onUserUpdate={onAuthenticatedUserUpdate}/>}/>
        </Routes>
      }
    </div>
  );
}

export default App;
