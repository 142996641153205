import config from '../config.json';
import axios from 'axios';

export class LeadService {
    createLead = async (full_name, phone, email, leadSource, subLeadSource) => {
        return (await axios.post(
            `${config.LEADS_API_URL}`, 
            {
                full_name, 
                phone, 
                email, 
                leadSource, 
                subLeadSource
            }, 
            { headers: {
                "Content-Type": "application/json"
            } })).data;
    }
}