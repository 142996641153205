import { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';

import VideoPlayer from '../../shared-components/VideoPlayer/VideoPlayer';
import { LeadService } from '../../services/LeadService';
import { InternalMessageService } from '../../services/InternalMessageService';

export default function AddToElevatedHomeWidget(props)
{
    const [saving, setSaving] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {        
        setName(props.user.name);
        setPhone(cleanPhone(props.user.phone));
        setEmail(props.user.email);
    }, []);

    const getFooterText = () => {
        const type = props.type ?? "Solar";
        return type === "Solar" ? "Watch Solar Energy Video"
            : type === "Energy Efficiency" ? "Watch Energy Efficiency Video"
            : "Watch Curb Video"
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const contactMe = async () => {
        try 
        {
            if(name === "" || phone === "" || email === "")
            {
                props.showWarnMessage('Please fill all the fields');
                return;
            }

            if(!isValidEmail(email)){
                props.showWarnMessage('This e-mail address is not valid');
                return;
            }

            setSaving(true);
            if(props.action == "CreateLead"){
                const service = new LeadService();
                await service.createLead(name, phone, email, props.leadSource, props.subLeadSource);
            }
            else if(props.action == "SendInternalMessage"){
                const service = new InternalMessageService();
                await service.sendMessage(props.from, props.to, props.subject, props.body);
            }
            
            setSaving(false);
            props.onFinish();
            return;
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            props.showErrorMessage(error.message ? error.message : error);
            return;
        }
    }

    return <>
        <div className="widget add-to-elevated-home">
            <div className="body">
                <div className="title">We will contact you!</div>
                <div className="sub-title">Confirm your information and your advisor will contact you shortly.</div>
                <div className="form">
                    <InputText disabled={saving} type="text" value={name} onChange={(e) => setName(e.target.value)}  placeholder="Full name"/>    
                    <InputMask 
                            disabled={saving}
                            autoClear={false} 
                            mask="(999) 999-9999" 
                            value={phone || ""} 
                            placeholder="Phone" 
                            unmask={true} 
                            onChange={(e) => setPhone('mobile', cleanPhone(e.value))}
                        />    
                    <InputText disabled={saving} type="text" value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="E-mail"/>
                    <div className="contact-me-button" onClick={contactMe}>Contact Me</div>
                </div>                
            </div>
            <div className="footer">
                <div className="icon"></div>
                <div className="label" onClick={() => setShowVideo(true)}>{ getFooterText() }</div>
                <VideoPlayer 
                    showVideo={showVideo} 
                    label={props.type}
                    videoURL={props.videoURL}
                    onCloseVideo={() => setShowVideo(false)}
                />
            </div>
        </div>
    </>;
}