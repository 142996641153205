import moment from 'moment';
import { useState, useRef } from 'react';
import VideoPlayer from '../../shared-components/VideoPlayer/VideoPlayer';
import AddToElevatedHomeWidget from './AddToElevatedHomeWidget';
import { Calendar } from 'primereact/calendar';

import { Toast } from 'primereact/toast';
import { InternalMessageService } from '../../services/InternalMessageService';

export default function EEWidget(props) {

    const [showVideo, setShowVideo] = useState(false);
    const videoURL = "https://media.elevation-one.com/energyefficiency.mp4";

    const [showReschedule, setShowReschedule] = useState(false);
    const [desiredDate, setDesiredDate] = useState(null);

    const getSubTitle = () => {
        if(props.service.isEEElevated()){
            return "Your Elevated Home is highly energy efficient. Use these tips for more!";
        }
        else if(props.service.isEEToBeInstalled()){
            return "Your Energy Efficiency home assesment session is scheduled.";
        }
        else {
            return "Seal and insulate your home for a controlled temperature year round.";
        }
    }

    const sendReescheduleEmail = async () => {

        if((desiredDate ?? "") === ""){
            toast.current.show({severity:'warn', summary: 'Please select a date', detail:'', life: 10000});    
        }
        else {
            try {
                const service = new InternalMessageService();
                await service.sendMessage(
                    "Customers Portal <customersportal@elevation-one.com>", 
                    ["Elevation <hello@poweredbyelevation.com>"], 
                    `EE Installation Reschedule Request on Customer Portal - ${props.user.name} - ${props.user.email}`, 
                    `Name: ${props.user.name}<br/>Email: ${props.user.email}<br/>Phone: ${props.user.phone}<br/>Current Appointment: ${ moment(props.service.getEEInstallationDate()).format("MM/DD/YYYY") }, at ${ moment(props.service.getEEInstallationDate()).format("hh:mma") }<br/>Reschedule to: ${ moment(desiredDate).format("MM/DD/YYYY") }, at ${ moment(desiredDate).format("hh:mma") }`
                );
                toast.current.show({severity:'success', summary: 'Done!', detail:'We will get in touch to reschedule your appointment', life: 10000});
                setShowReschedule(false);
            } catch (error) {
                console.log(error);
            }
        }
        
    }

    const getCoverImage = () => {
        if(props.service.isEEElevated()){
            return <div className='cover-image on'></div>;
        }
        else if(props.service.isEEToBeInstalled()){

            if(showReschedule){
                return <div className='cover-image reschedule'>
                    <div className='reschedule-title'>Current Appointment</div>
                    <div className='reschedule-subttle'>{ moment(props.service.getEEInstallationDate()).format("MM/DD/YYYY") }, at { moment(props.service.getEEInstallationDate()).format("hh:mma") }</div>
                    <i className="pi pi-angle-down" style={{ fontSize: '1.5rem', color: '#80c1ff' }}></i>
                    <div className='reschedule-title'>Reschedule to</div>
                    <Calendar style={{width: '70%'}} value={desiredDate} onChange={(e) => setDesiredDate(e.value)} showTime showSeconds={false} hourFormat="12" placeholder='Click to select date'/>
                    <div className='reschedule-buttons'>
                        <div className='reschedule-button accept' onClick={() => sendReescheduleEmail()}>Accept</div>
                        <div className='reschedule-button cancel' onClick={() => setShowReschedule(false)}>Cancel</div>
                    </div>
                </div>
            }
            else {
                return <div className='cover-image to-be-installed'>We will work in your home on { moment(props.service.getEEInstallationDate()).format("MM/DD/YYYY") }, at { moment(props.service.getEEInstallationDate()).format("hh:mma") }</div>;
            }
        }
        else {
            return <>
                <div className='cover-image off' onClick={() => setShowVideo(true)}></div>
                <VideoPlayer 
                    showVideo={showVideo} 
                    label="Energy Efficiency"
                    videoURL={videoURL}
                    onCloseVideo={() => setShowVideo(false)}
                />
            </>;
        }
    }

    const getCTA = () => {
        if(showReschedule){
            return <div className="label"></div>
        }
        else if(props.service.isEEElevated()){
            return <div className="label" onClick={() => window.open("https://www.poweredbyelevation.com/energy-efficiency")}>READ MORE ON EFFICIENCY →</div>;
        }
        else if(props.service.isEEToBeInstalled()){
            return <div className="label" onClick={() => setShowReschedule(true)}>RESCHEDULE APPOINTMENT →</div>;
        }
        else {
            return <div className="label" onClick={() => setShowAddToElevatedHomeWidget(true)}>ADD TO YOUR ELEVATED HOME</div>;
        }
    }

    const [showAddToElevatedHomeWidget, setShowAddToElevatedHomeWidget] = useState(false);

    const toast = useRef(null);
    if(showAddToElevatedHomeWidget)
    {        
        return <>
            <Toast ref={toast}  position="center"/>
            <AddToElevatedHomeWidget 
                type="Energy Efficiency" 
                user={props.user} 
                videoURL={videoURL} 
                showWarnMessage={(message) => toast.current.show({severity: 'warn', summary: 'Warning', detail: message, life: 10000})}
                showErrorMessage={(message) => toast.current.show({severity: 'error', summary: 'Ooops... An error occurred', detail: message, sticky: true})}
                onFinish={() => {
                    toast.current.show({severity: 'success', summary: 'Success', detail: 'We will contact you soon', life: 10000});
                    setShowAddToElevatedHomeWidget(false)}
                }
                action="CreateLead"
                leadSource="Energy Efficiency"
                subLeadSource="Customer Portal"
            />
        </>
    }

    return <>
        <Toast ref={toast}  position="center"/>
        <div className="widget ee">
            <div className="body">
                <div className="title">Energy Efficiency</div>
                <div className="sub-title">{ getSubTitle() }</div>
                { getCoverImage() }
            </div>
            <div className="footer dark">
                { !props.service.isEEElevated() && !props.service.isEEToBeInstalled() && <div className="icon"></div> }
                { getCTA() }
            </div>
        </div>
    </>;
}