import { useState, useEffect } from "react";

import * as _ from 'lodash';
import * as uuid from 'uuid';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from "primereact/dialog";
import { ReferralService } from "../../../services/ReferralService";
import { ProgressBar } from "primereact/progressbar";

export default function AddReferral(props){

    const [referral, setReferral] = useState({
        id: uuid.v4(),
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        status: ""
    });

    const [submitted, setSubmitted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const updateValue = (fieldName, value) => {
        const _referral  = Object.assign({}, referral);
        _.set(_referral, fieldName, value);
        setReferral(_referral);
        setSubmitted(false);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const isDataValid = () => {
        if(referral.first_name === "" || referral.last_name === "" || referral.mobile === "" || (referral.email !== "" & !isValidEmail(referral.email))){
            return false;
        }

        if(props.referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referral.mobile) && r.id !== referral.id)){
            return false;
        }

        if(props.referrals.find(r => r.email !== "" && r.email === referral.email && r.id !== referral.id)){
            return false;
        }
        return true;
    }

    const save = async () => {
        try 
        {
            setSubmitted(true);
            setErrorMessage("");
            if(isDataValid())
            {
                setSaving(true);
                const service = new ReferralService();
                props.onReferralCreated(await service.save(referral));
                setSaving(false);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 400){
                setErrorMessage(error.message);
            }
            else {
                alert(error);
            }            
        }
    }

    return (<>
        <Dialog
                className="dialog"
                header={<div className="new-referral-dialog-title">New Referral</div>}
                visible={true}
                style={{width: window.innerWidth > 1000 ? '500px' : '95vw'}}
                onHide={props.onHide}
                >
                <>
                    <div className="new-referral-dialog-form">
                        <InputText 
                            disabled={saving}
                            value={referral.first_name || ""} 
                            onChange={(e) => updateValue('first_name', e.target.value)} 
                            placeholder="First Name"
                        />
                        { submitted && referral.first_name === "" && <div className="referral-error">First name is mandatory</div>}
                        <InputText 
                            disabled={saving}
                            value={referral.last_name || ""} 
                            onChange={(e) => updateValue('last_name', e.target.value)} 
                            placeholder="Last Name" 
                        />
                        { submitted && referral.last_name === "" && <div className="referral-error">Last name is mandatory</div>}
                        <InputMask 
                            disabled={saving}
                            autoClear={false} 
                            mask="(999) 999-9999" 
                            value={referral.mobile || ""} 
                            placeholder="Mobile Phone #" 
                            unmask={true} 
                            onChange={(e) => updateValue('mobile', cleanPhone(e.value))}
                        />
                        { submitted && (errorMessage || "") === "PHONE_ALREADY_REFERRED" && <div className="referral-error">Mobile number was already referred</div>}
                        { submitted && referral.mobile === "" && <div className="referral-error">Mobile is mandatory</div>}
                        { props.referrals.find(r => r.mobile !== "" && cleanPhone(r.mobile) === cleanPhone(referral.mobile) && r.id !== referral.id) && <div className="referral-error">Duplicate mobile number</div> }
                        <InputText 
                            disabled={saving}
                            value={referral.email || ""} 
                            onChange={(e) => updateValue('email', e.target.value)} 
                            placeholder="E-Mail Address" 
                        />
                        { submitted && (errorMessage || "") === "EMAIL_ALREADY_REFERRED" && <div className="referral-error">Email was already referred</div>}
                        { submitted && referral.email !== "" && !isValidEmail(referral.email) && <div className="referral-error">Email is invalid</div>}
                        { props.referrals.find(r => r.email !== "" && r.email === referral.email && r.id !== referral.id) && <div className="referral-error">Duplicate email address</div> }

                        
                    </div>
                    
                    <div className="new-referral-buttons">    
                        <Button disabled={saving} label="Save" className="button" onClick={save}  icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                </>
            </Dialog>
    </>);
}