import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

export default function VideoPlayer(props){

    const videoPlayer = useRef(null);
    const [playing, setPlaying] = useState(true);

    return <>
        <Dialog header={props.label} visible={props.showVideo} className="video-dialog" onHide={() => { setPlaying(false); props.onCloseVideo()}}>
            <div className="content">
            { !playing && <div className='player-button' onClick={() => setPlaying(true)}></div>}
            <ReactPlayer width='100%'
                        height='100%' 
                        ref={videoPlayer}
                        playing={playing} onPause={() => setPlaying(false)} controls={playing} url={props.videoURL}/>
            </div>
        </Dialog>
    </>;
}