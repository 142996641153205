import Header from "../../shared-components/Header/Header";
import Footer from "../../shared-components/Footer/Footer";

import './css/desktop.css'
import './css/mobile.css'
import { SolarTrackerService } from '../../services/SolarTrackerService';
import EEWidget from './EEWidget';
import CurbWidget from './CurbWidget';
import ReferralsWidget from './ReferralsWidget';
import TrackerWidget from './TrackerWidget';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

import amplitude from 'amplitude-js';
import config from '../../config.json';
import { useEffect, useState, useRef } from "react";
import { SurveyService } from "../../services/SurveyService";

export default function DashboardScreen(props) {

    amplitude.getInstance().init(config.AMPLITUDE_KEY);

    useEffect(()=>{
        amplitude.getInstance().logEvent("Dashboard Screen Loaded");
    },[]);

    const solarTrackerService = new SolarTrackerService(props.user);

    const toast = useRef(null);
    const [showSurveyModal, setShowSurveyModal] = useState(props.user.survey ? true : false)
    const [surveyAnswer, setSurveyAnswer] = useState({})
    const updateSurveyAnswer = (field, value) => {
        const _surveyAnswer = Object.assign({}, surveyAnswer)
        _surveyAnswer[field] = value
        setSurveyAnswer(_surveyAnswer)
    }

    const submitSurvey = async () => {
        try {
            const service = new SurveyService();
            await service.submitAnswer(props.user.survey.id, surveyAnswer);
            toast.current.show({severity:'success', summary: 'Answer Submitted!', detail:'Thanks for sharing your opiion with us', life: 5000});

            const _user = Object.assign({}, props.user)
            delete _user.survey
            setShowSurveyModal(false)
            await props.onUserUpdate(_user)
        } catch (error) {
            console.log(error)
            toast.current.show({severity:'error', summary: 'An error occurred', detail:'Please try again. If the error persist please contact ourr team'});
        }
    }

    return <div className="screen internal dashboard">
        <Toast ref={toast}  position="center"/>
        { showSurveyModal &&
        <Dialog header="Survey" visible={true} onHide={() => setShowSurveyModal(false)} footer={<div style={{ marginTop: '10px'}}>
            <Button label="Submit" disabled={!surveyAnswer.option} icon="pi pi-check" onClick={submitSurvey} />
        </div>}>
            <div className="survey-question" style={{ marginTop: '10px'}}>
                { props.user.survey.question.text}
            </div>
            <div className="survey-options">
                {[1,2,3,4,5,6,7,8,9,10].map(i => <div key={`op_${i}`} className={`survey-option ${ i === surveyAnswer.option ? 'selected' : ''}`} onClick={() => updateSurveyAnswer("option", i)}>{i}</div> )}
            </div>
            <div className="survey-comments">
                <InputTextarea value={surveyAnswer.comments ?? ""} placeholder="Please leave your comments here" onChange={(e) => updateSurveyAnswer("comments", e.target.value)} rows={4}/>
            </div>
        </Dialog>}
        <Header user={props.user}/>
        <div id="main">
            <div id="top-bar">
                <div id="content">
                    <div id="titles">
                        <div id="title">Hello {props.user.name.split(" ")[0]}!</div>
                        <div id="sub-title" dangerouslySetInnerHTML={{ __html: solarTrackerService.getDashboardSubtitle()}}></div>
                    </div>
                    <div id="indicators">
                        <div id="elevated-home">
                            <div id="progress" style={{ background: `conic-gradient(from 180deg, #81d553 0deg ${Math.round(solarTrackerService.getElevatedHomePercentage()*360/100)-(solarTrackerService.getElevatedHomePercentage() === 100 ? 0 : 10)}deg, #2a579c ${Math.round(solarTrackerService.getElevatedHomePercentage()*360/100)}deg 360deg)`}}>
                                <div id="icon">{ solarTrackerService.getElevatedHomePercentage() }%</div>
                            </div>
                            <div id="label">Elevated Home</div>
                        </div>
                        <div id="other-indicators">
                            <div className={`indicator solar ${solarTrackerService.isSolarElevated() ? 'on' : 'off' }`}>
                                <div id="icon"></div>
                                <div id="label">Solar</div>
                            </div>
                            <div className={`indicator curb ${solarTrackerService.isCurbElevated() ? 'on' : 'off' }`}>
                                <div id="icon"></div>
                                <div id="label">Curb</div>
                            </div>
                            <div className={`indicator ee ${solarTrackerService.isEEElevated() ? 'on' : 'off' }`}>
                                <div id="icon"></div>
                                <div id="label">Efficiency</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="widgets">
                <ReferralsWidget user={props.user}/>
                <TrackerWidget service={solarTrackerService} user={props.user}/>
                <CurbWidget service={solarTrackerService} user={props.user}/>
                <EEWidget service={solarTrackerService} user={props.user}/>
            </div>
            <div id="rollover-row"></div>
            { false &&
            <div id="documents">
                <div id="title">
                    <span>Your Documents</span>
                    <i className="pi pi-chevron-down"></i>
                </div>
                <div className="list">
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                </div>
                <div className="list">
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                    <div className="document">
                        <div className="icon"></div>
                        <div className="name">Elevation_Solar_Contract_10/29/22_Signed.pdf</div>
                    </div>
                </div>
            </div>}
        </div>
        <Footer user={props.user}/>
    </div>
}