import { BaseService } from './_BaseService';

export class ReferralService extends BaseService {

    batchSave = async (referrals, energyConsultant) => {
        return await this.post(`batch-save-referrals${ energyConsultant ? `?ec=${energyConsultant}`:''}`, referrals);
    }

    partnersBatchSave = async (referrals, school) => {
        return await this.post(`partners-batch-save-referrals`, { referrals, school });
    }

    save = async (referral) => {
        return await this.post(`save-referral`, referral);
    }

    updateReferralPaymentOption = async (id, option) => {
        return await this.post(`update-referral-option`, { id, option });
    }

    static PaymentOptions = {
        donation: 'donation',
        cash: 'cash'
    }

    static getSchoolName = (user) => {
        return ((((user || {}).paymentConfig || {}).config || {}).school || {}).name  || "";
    }

    static getCashMethodDefinition = (user) => {
        return (((user || {}).paymentConfig || {}).config || {}).preferred || "";
    }

    static hasCashMethodDefinition = (user) => {
        return this.getCashMethodDefinition(user) !== "";
    }

    static getRefferalCount = (user) => {
        return (user.referrals || []).length;
    }

    static getPaymentStatus = (payment) => {
        return (payment || {}).status || "NOT-CREATED";
    }

    static getPaymentOption = (payment) => {
        return (((payment || {}).data || {}).config || {}).option || {};
    }

    static getPaymentAmount = (payment) => {
        return ((payment || {}).data || {}).total_amount || 0;
    }
    
    static hasPaidCash = (referral, payments) => {
        return ReferralService.getPaidCash(referral, payments) > 0;
    }

    static getTotalAmountPerType = (referrals, payments, option) => {
        let amount = 0;
        payments.filter(p => p.status === "PAID" && ReferralService.getPaymentOption(p) === option).forEach(payment => {
            amount += ReferralService.getPaymentAmount(payment);
        });
        return amount;
    }

    static getPaidCash = (referral, payments) => {
        const paidMeetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.cash);
        const paidFinalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.cash);
        return ReferralService.getPaymentAmount(paidMeetingPayment) + ReferralService.getPaymentAmount(paidFinalPayment);
    }

    static getPaidDonation = (referral, payments) => {
        const paidMeetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.donation);
        const paidFinalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.donation);
        return ReferralService.getPaymentAmount(paidMeetingPayment) + ReferralService.getPaymentAmount(paidFinalPayment);
    }

    static hasPaidDonation = (referral, payments) => {
        return ReferralService.getPaidDonation(referral, payments) > 0;
    }

    static hasOpenPayment = (referral, payments) => {
        const paidMeetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.cash);
        const paidFinalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS" && p.status === "PAID" && ReferralService.getPaymentOption(p) === this.PaymentOptions.cash);
        return ReferralService.getPaymentStatus(paidMeetingPayment) !== "PAID" || ReferralService.getPaymentStatus(paidFinalPayment) !== "PAID";
    }

    static showMeetingPaymentLink = (referral, payments) => {
        const meetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING");
        return meetingPayment && meetingPayment.status !== "PENDING_APPROVAL";
    }

    static getMainStatus = (referral) => {

        let _status = "Checking";
        if(referral.status === "MEETING_SCHEDULED"){
            _status = "Scheduled"
        }
        else if(referral.status === "MEETING_DONE"){
            _status = "Meeting Complete";
        }
        else if(referral.status === "ACTIVATED"){
           _status = "Complete";
        }
        else if(referral.status === "Not Interested"){
            _status = "Not Qualified";
        }

        return _status;
    }

    static isPaymentMethodLocked = (referral, payments) => {
        const meetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING");
        if(meetingPayment){
            if(
                (meetingPayment.status === "PENDING_CONFIG" &&  ((referral.payment_config || {}).option || "") !== "") 
                || meetingPayment.status === "PENDING_PAYMENT"
                || meetingPayment.status === "PAID")
            {
                return true
            }
        }

        const finalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS");
        if(finalPayment){
            if(
                (finalPayment.status === "PENDING_CONFIG" &&  ((referral.payment_config || {}).option || "") !== "") 
                || finalPayment.status === "PENDING_PAYMENT"
                || finalPayment.status === "PAID")
            {
                return true
            }
        }
        return false;
    }

    static getAppointmentStatus = (referral, payments) => {

        let _status = "Contacting";
        if(referral.status === "MEETING_SCHEDULED"){
            _status = "Scheduled"
        }
        else if(referral.status === "MEETING_DONE" || referral.status === "ACTIVATED"){
            _status = "Scheduled";
            const meetingPayment = payments.find(p => p.referral_id === referral.id && p.type === "MEETING");
            if(meetingPayment){
                if(meetingPayment.status === "PENDING_APPROVAL"){
                    _status = "Scheduled";
                }
                else if(meetingPayment.status === "PENDING_CONFIG")
                {
                    if(((referral.payment_config || {}).option || "") === ""){
                        _status = "Select Payment Method";    
                    }
                    else {
                        _status = `Processing Payment`;    
                    }
                }
                else if(meetingPayment.status === "PENDING_PAYMENT"){
                    //_status = `Processing ${(meetingPayment.data.config.option || "") === "donation" ? "Donation" : "Cash"} Payment`;
                    _status = `Processing Payment`;
                }
                else if(meetingPayment.status === "PAID"){
                    _status = `${(meetingPayment.data.option || "") === "donation" ? "Donated" : "Paid Cash"} $${meetingPayment.data.total_amount}`;
                }
            }
        }
        else if(referral.status === "Not Interested"){
            _status = "Not Qualified";
        }
        else if(referral.status === "Submitted"){
            _status = "Submitted";
        }

        return _status;
    }

    static showActivationPaymentLink = (referral, payments) => {
        const finalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS");
        return finalPayment && finalPayment.status !== "PENDING_APPROVAL";
    }

    static getActivationStatus = (referral, payments) => {

        let _status = "-";
        if(referral.status === "MEETING_SCHEDULED"){
            _status = "-"
        }
        else if(referral.status === "MEETING_DONE"){
            _status = "-";
        }
        else if(referral.status === "ACTIVATED"){
            _status = "-";
            const finalPayment = payments.find(p => p.referral_id === referral.id && p.type === "DOCS");
            if(finalPayment){
                if(finalPayment.status === "PENDING_APPROVAL"){
                    _status = "-";    
                }
                else if(finalPayment.status === "PENDING_CONFIG"){
                    if(((referral.payment_config || {}).option || "") === ""){
                        _status = "Select Payment Method";    
                    }
                    else {
                        _status = `Processing Payment`;    
                    }
                }
                else if(finalPayment.status === "PENDING_PAYMENT"){
                    //_status = `Processing ${(finalPayment.data.config.option || "") === "donation" ? "Donation" : "Cash"} Payment`;  
                    _status = `Processing Payment`;  
                }
                else if(finalPayment.status === "PAID"){
                    _status = `${(finalPayment.data.option || "") === "donation" ? "Donated" : "Paid Cash"} $${finalPayment.data.total_amount}`;
                }
            }
        }

        return _status;
    }
}