import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../shared-components/Header/Header";

import { Dialog } from 'primereact/dialog';
import ReactPlayer from 'react-player';

import './css/desktop.css';
import './css/mobile.css';
import { SolarTrackerService } from "../../services/SolarTrackerService";

import amplitude from 'amplitude-js';
import config from '../../config.json';

export default function TrackerScreen(props){

    amplitude.getInstance().init(config.AMPLITUDE_KEY);

    useEffect(()=>{
        amplitude.getInstance().logEvent("Tracker Screen Loaded");
    },[]);

    const navigate = useNavigate();

    const solarTrackerService = new SolarTrackerService(props.user);
    const steps = solarTrackerService.getSteps();
    const [selectedStep, setSelectedStep] = useState(solarTrackerService.getCurrentStep());

    const videoPlayer = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [videoToShow, setVideoToShow] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    return <div className="screen internal tracker">
        <Header user={props.user}/>
        <div id="main">
            <div id="tracker-bar">
                <div id="back-to-home" onClick={() => navigate("/")}>
                    <i className="pi pi-home"></i>
                    <div id="label">Back to Dashboard</div>
                </div>
                <div id="content">
                    <div id="title">Track Your Solar Installation</div>
                    <div id="sub-title">Welcome to Elevation Solar Tracker. On this page, you will be able to track the status of your solar installation and learn more about what to expect.</div>
                    <div id="steps">
                        { steps.map((step, i) => <>
                            <div className="step">
                                <div className={`label ${step.complete || step.current ? 'complete' : 'incomplete'}`}>{step.label}</div>
                                <div className="outter-bar">
                                    <div className={`inner-bar ${(steps[i-1] || {}).complete ? 'complete' : 'incomplete'}`}/>
                                    <div className={`indicator ${step.complete || step.current ? 'complete' : 'incomplete'}`} onClick={() => setSelectedStep(step)}>
                                        <div className="inner-indicator"></div>
                                    </div>
                                    <div className={`inner-bar ${step.complete ? 'complete' : 'incomplete'}`}/>
                                </div>
                                <div className={`arrow ${step.index === selectedStep.index ? 'current' : ''}`}></div>
                            </div>
                        </>)}
                        
                    </div>
                </div>
            </div>
            <div id="footer-content">
                <div id="step-title-bar">
                    <div id="title">{ selectedStep.label }</div>
                    <div id="duration">
                        <div id="icon"></div>
                        <div id="timeframe">{selectedStep.duration}</div>
                    </div>
                </div>
                <div id="step-content-bar">
                    <div id="description">
                        <div className="title">WHAT IS THIS?</div>
                        <div className="text">{selectedStep.whatIsThis}</div>
                        <div className="title">WHAT SHOULD YOU EXPECT?</div>
                        <div className="text">{selectedStep.whatShoudYouExpect}</div>
                        <div className="title">WHAT CAN YOU DO?</div>
                        <div className="text">{selectedStep.whatCanYouDo}</div>
                    </div>
                    <div id="video-content">
                        { selectedStep.videos.map(video => <>
                            <div id="thumbnail" style={{backgroundImage: `url('${video.videoThumbURL}')`}}>
                                <div className='player-button' onClick={() => { setVideoToShow(video); setShowVideo(true); setPlaying(true); }}></div>
                            </div>
                            { video.title ? 
                                <div id="title">{video.title}</div>
                                : <div id="text"><b>Watch the video</b> to learn more about this step from one of experienced energy specialists.</div>
                            }
                        </>)}
                        
                    </div>
                </div>
                <div id="next-step-bar">
                    <div id="icon"></div>
                    <div id="text">
                        <span>NEXT STEP →</span>
                        <span><b>{selectedStep.nextStepName}</b></span>
                    </div>
                </div>
            </div>
        </div>
        <Dialog header={videoToShow?.title ?? selectedStep.label} visible={showVideo} className="video-dialog" onHide={() => { setPlaying(false); setShowVideo(false)}}>
            <div className="content">
            { !playing && <div className='player-button' onClick={() => setPlaying(true)}></div>}
            <ReactPlayer width='100%'
                        height='100%' 
                        ref={videoPlayer}
                        playing={playing} onPause={() => setPlaying(false)} controls={playing} url={videoToShow?.videoURL}/>
            </div>
        </Dialog>
    </div>
}