// React Imports
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// PrimeReact imports
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { InputMask } from 'primereact/inputmask';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';

// Css imports
import './css/desktop.css';
import './css/mobile.css';

//Services imports
import { AuthorizationService } from '../../services/AuthorizationService';
import { SignUpService } from '../../services/SignUpService';

import amplitude from 'amplitude-js';
import config from '../../config.json';

export default function SignupScreen(props) {

    amplitude.getInstance().init(config.AMPLITUDE_KEY);

    useEffect(()=>{
        amplitude.getInstance().logEvent("Signup Screen Loaded");
    },[]);

    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/");
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    
    const [code, setCode] = useState("");

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [saving, setSaving] = useState(false);
    const [invalidData, setInvalidData] = useState(false);
    const [showAccountAlreadyActiveMessage, setShowAccountAlreadyActiveMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [authenticating, setAuthenticating] = useState(false);

    const STEPS = {
        DATAINPUT: 0,
        EMAILVERIFICATION: 1,
        PASSWORDCREATION: 2
    };
    const [currentStep, setCurrentStep] = useState(STEPS.DATAINPUT);

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    const isValidEmail = (email) => 
    {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(email))
        {
            return (true);
        }
        return (false)
    }

    const isValidData = () => {

        if(firstName === "" || lastName === "" || email === ""|| phone === ""){
            setErrorMessage("Please fill all the fields");
            return false;
        }

        if(!isValidEmail(email)){
            setErrorMessage("Please provide a valid email address.");
            return false;
        }

        if(!termsAccepted){
            setErrorMessage("Please accept our Terms and Conditions");
            return false;
        }
        
        return true;
    }

    const checkEmailAndPhone = async () => 
    {
        try 
        {
            setInvalidData(false);
            setErrorMessage("");
            setShowAccountAlreadyActiveMessage(false);
            if(!isValidData()){
                setInvalidData(true);
            }
            else {
                setSaving(true);
                const authorizationService = new AuthorizationService();
                const user = await authorizationService.checkEmailAndPhone(firstName, lastName, email, phone);
                // props.onAuthenticated(user);
                setSaving(false);
                setCurrentStep(STEPS.EMAILVERIFICATION);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            if(error.code && error.code === 430){
                setShowAccountAlreadyActiveMessage(true);
            }
            else {
                alert(error);
            }            
        }
    }

    const sendCode = async () => {
        try {
            setSaving(true);
            setCode("");
            setInvalidData(false);
            setErrorMessage("");
            const service = new SignUpService();
            await service.sendToken(firstName + " " + lastName, email);
            setSaving(false);
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message || error.toString());
        }
        
    }

    const checkCode = async () => {
        
        try {
            setSaving(true);
            setCode("");
            setInvalidData(false);
            setErrorMessage("");
            const service = new SignUpService();
            await service.checkToken(code);
            setSaving(false);
            setCurrentStep(STEPS.PASSWORDCREATION);
        } 
        catch (error) 
        {
            console.log(error);
            setSaving(false);
            if(error.code && error.code === 400){
                setInvalidData(true);
                setErrorMessage("Invalid verification code");
            }
            else {
                alert(error.message || error.toString());
            }
        }
    }

    const savePassword = async () => {
        setInvalidData(false);
        setErrorMessage("");
        if(password !== passwordConfirmation)
        {
            setInvalidData(true);
            setErrorMessage("Password confirmation does not match");
        }
        else {
            try {
                setSaving(true);
                const authorizationService = new AuthorizationService();
                const service = new SignUpService();
                const updatedUser = await service.savePassword(password);
                setSaving(false);
                props.onSignedUp(updatedUser);
            } 
            catch (error) 
            {
                setSaving(false);
                console.log(error);
                if(error.code && error.code === 400){
                    setInvalidData(true);
                    setErrorMessage("Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.");
                }
                else {
                    alert(error.message || error.toString());
                }
            }
        }
    }

    return <div className="screen external signup">
        <div id="main">
            <div id="left">
                <div id="logo"></div>
                { currentStep === STEPS.DATAINPUT && 
                    <>
                    <div id="title">
                        Sign Up to<br/>
                        Elevation<br/>
                        Customer Portal
                    </div>
                    <div id="form">
                        <div className="field">
                            <InputText disabled={saving} value={firstName} onChange={(e) => setFirstName(e.target.value)}  placeholder="First Name"/>
                        </div>
                        <div className="field">
                            <InputText disabled={saving} value={lastName} onChange={(e) => setLastName(e.target.value)}  placeholder="Last Name"/>
                        </div>
                        <div className="field">
                            <InputText disabled={saving} value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="E-mail Address"/>
                        </div>
                        <div className="field">
                            <InputMask 
                                        disabled={saving}
                                        autoClear={false} 
                                        mask="(999) 999-9999" 
                                        value={phone || ""} 
                                        placeholder="Phone Number" 
                                        unmask={true} 
                                        onChange={(e) => setPhone(cleanPhone(e.value))}
                                    />
                        </div>
                        <div className="field checkbox">
                            <Checkbox inputId="terms" name="terms" onChange={(e) => setTermsAccepted(e.checked)} checked={termsAccepted} />
                            <label htmlFor="terms" className="ml-2">Please accept our <a href="https://www.poweredbyelevation.com/terms" target={"_blank"}>Terms and Conditions</a></label>
                        </div>
                        { false && <div className="field">
                            <Password feedback={false} toggleMask={true} value={password} onChange={(e) => setPassword(e.target.value)}  placeholder="Create a Password"/>
                        </div>}
                        { showAccountAlreadyActiveMessage && <div className="error-message">An active account is already using this email address or this phone number. Please&nbsp;<span class="login" onClick={goToLogin}>log in</span>.</div> }
                        { invalidData && <div className="error-message">{ errorMessage }</div> }
                        <div id="sign-up-button" className='button' onClick={checkEmailAndPhone}></div>
                        { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />  }
                        <div id="links">
                            <div className="link-item">
                                <span className="icon">→</span>
                                <span className="label">Already have an account?</span>
                                <span className="link" onClick={goToLogin}>Log In.</span>
                            </div>
                        </div>
                    </div>    
                    </>
                }

                { currentStep === STEPS.EMAILVERIFICATION && 
                    <>
                    <div id="title">E-mail verification</div>
                    <div id="subtitle">We just sent an e-mail to <u>{email}</u> with a verification code. Please enter your code below.</div>
                    <div id="form">
                        <div className="field">
                            <InputText disabled={saving} value={code} placeholder="XXXX"  onChange={(e) => setCode(e.target.value)}/>
                        </div>
                        { invalidData && <div className="error-message">{ errorMessage }</div> }
                        <Button disabled={code === ""} label="Continue" className="button" onClick={checkCode} icon="pi pi-arrow-right" iconPos="right"/>
                        { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />  }
                        <div id="links">
                            <div className="link-item">
                                <span className="icon">→</span>
                                <span className="label">Already have an account?</span>
                                <span className="link" onClick={goToLogin}>Log In.</span>
                            </div>
                            <div className="link-item">
                                <span className="icon">→</span>
                                <span className="label">Didn't receive the code?</span>
                                <span className="link" onClick={sendCode}>Send again.</span>
                            </div>
                        </div>
                    </div>
                    </>
                }

                { currentStep === STEPS.PASSWORDCREATION && 
                    <>
                    <div id="title">Almost there!</div>
                    <div id="subtitle">Now, create your password to access your dashboard.</div>
                    <div id="form">
                        <div className="field">
                            <Password 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Enter Password"
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                                promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                strongLabel="We are good to go."
                                toggleMask={true}
                            />
                        </div>
                        <div className="field">
                            <Password 
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                placeholder="Confirm Password" 
                                feedback={false}
                                toggleMask={true}
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                            />
                        </div>
                        { invalidData && <div className="error-message">{ errorMessage }</div> }
                        <Button disabled={password === "" || passwordConfirmation === ""} label="Login" className="button" onClick={savePassword} icon="pi pi-arrow-right" iconPos="right"/>
                        { saving && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />  }
                    </div>
                    </>
                }

                
            </div>
            <div id="right"></div>
        </div>
    </div>
}