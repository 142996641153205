import { useState, useEffect } from "react";

import { ReferralService } from "../../../../services/ReferralService";

import { Button } from 'primereact/button';

import CashConfigurator from "../CashConfigurator";
import { SchoolSelector } from "../SchoolSelector";

import './css/desktop.css';
import './css/13inch.css';
import './css/mobile.css';

export default function PaymentOptions(props)
{
    const [hasCashDef, setHasCashDef] = useState(false);
    const [cashMethod, setCashMethod] = useState("");
    const [showCashMethodConfig, setShowCashMethodConfig] = useState(false);
    
    useEffect(() => {

        const loadData = () => {
            setHasCashDef(ReferralService.hasCashMethodDefinition(props.user));
            setCashMethod(ReferralService.getCashMethodDefinition(props.user));
        }

        loadData();
        
    }, []);

    const update = async (user) => {
        await props.onUserUpdate(user);
        setShowCashMethodConfig(false);
    }

   return (<>
        <div className="payment-options">
            <div className="text">In order to get paid on time, please enter your payment method and account details.</div>
            <div className="card green">
                <div className="card-header">
                    <div className="card-title">Get Paid</div>
                    <img alt="Cash" src="/assets/referrals/images/icon-hand-cash.png"/>
                </div>
                { hasCashDef && <div className="card-subtitle">Via <span>{cashMethod}</span></div> }
                <div className="buttons">    
                    <Button label={hasCashDef ? "Change Payment Method" : "Select Payment Method"} className="button" onClick={() => setShowCashMethodConfig(true)} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>
        </div>
        { 
            showCashMethodConfig &&
            <CashConfigurator user={props.user} onUpdate={update} onHide={() => setShowCashMethodConfig(false)}/>
        }
    </>);
}