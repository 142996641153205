import React, { useState, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';

import * as _ from 'lodash';

import { AccountService } from '../../../../services/AccountService';
import { ProgressBar } from 'primereact/progressbar';

import './css/desktop.css';
import './css/13inch.css';
import './css/mobile.css';

export default function ProfileInformation(props) 
{
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState(props.user);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const isDataValid = () => { 

        if((user.name || "") === "" || (user.phone || "") === ""){
            return false;
        }

        return true;
    }

    const save = async () => {
        try 
        {
            setShowSuccessMessage(false);
            setSubmitted(true);
            if(isDataValid()){
                setSaving(true);
                const service = new AccountService();
                await service.updateProfile(user);
                setSaving(false);
                props.onUserUpdate(user);
            }
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message ? error.message : error);
        }
    }

    const updateValue = (fieldName, value) => {
        const _user = Object.assign({}, user) 
        _.set(_user, fieldName, value);
        setUser(_user);
        //setShowSuccessMessage(false);
        setSubmitted(false);
    }

    const cleanPhone = (phone) => {
        return phone.split("(").join("")
            .split(")").join("")
            .split(" ").join("")
            .split("_").join("")
            .split("-").join("");
    }

    return (
        <div className="profile-information">
            <div className="text">Edit and update your personal information here. We may use this to contact you about your referrals and payments.</div>
            <div className="form">
                <div className="form-field">
                    <InputText disabled={loading || saving} value={user.name || ""} onChange={(e) => updateValue('name', e.target.value)} />
                </div>
                <div className="form-field">
                    <InputMask disabled={loading || saving} autoClear={false} mask="(999) 999-9999" value={user.phone || ""} placeholder="(___) ___-____" unmask={true} onChange={(e) => updateValue('phone', cleanPhone(e.value))}></InputMask>
                </div>
                <div className="form-field">
                    <InputText disabled tooltip="Email can't be changed" value={user.email || ""}/>
                </div>
            </div>
            { submitted && user.name === "" && <div className="user-error">Name is mandatory</div>}
            { submitted && user.phone === "" && <div className="user-error">Phone is mandatory</div>}
            <div className="buttons">    
                <Button disabled={saving} label="Save" className="button" onClick={save}  icon="pi pi-arrow-right" iconPos="right"/>
            </div>
            { (saving || loading) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
        </div>
    )
}