import { useState } from "react"
import Earnings from "../Earnings/Earnings";
import PaymentOptions from "../PaymentOptions/PaymentOptions";
import ProfileInformation from "../ProfileInformation/ProfileInformation";

import './css/desktop.css';
import './css/13inch.css';
import './css/mobile.css';

export default function Tabs(props) 
{
    const [selectedIndex, setSelectedIndex] = useState(1);

    return <div className="tabs-body">
        <div className="tabs-header">
            <div className={`tab-header${ selectedIndex === 1 ? ' selected' : ''}`} onClick={() => setSelectedIndex(1)}>YOUR EARNINGS</div>
            <div className={`tab-header${ selectedIndex === 2 ? ' selected' : ''}`} onClick={() => setSelectedIndex(2)}>PAYMENT<br/>OPTIONS</div>
            <div className={`tab-header${ selectedIndex === 3 ? ' selected' : ''}`} onClick={() => setSelectedIndex(3)}>PROFILE<br/>INFORMATION</div>
        </div>
        <div className="tabs-content">
            { selectedIndex === 1 && <Earnings user={props.user} payments={props.payments}/>}
            { selectedIndex === 2 && <PaymentOptions user={props.user} onUserUpdate={props.onUserUpdate}/>}
            { selectedIndex === 3 && <ProfileInformation user={props.user} onUserUpdate={props.onUserUpdate}/>}
        </div>
    </div>
}