import './css/desktop.css';
import './css/mobile.css';

import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthorizationService } from '../../services/AuthorizationService';

export default function Header(props){
    const menu = useRef(null);
    const navigate = useNavigate();
    const signOut = () => {
        const authorizationService = new AuthorizationService();
        authorizationService.logout();
        window.location.href = "/";
    }

    let items = [{label: 'Sign Out', icon: 'pi pi-fw pi-power-off', command:()=>{ signOut() }} ];

    return <div className="header">
        <div className='main'>
            <div className="left">
                <div className="logo" onClick={() => navigate("/")}></div>
                <div className="title">Customer Portal</div>
            </div>
            { props.showBack &&
            <div className="backbutton">
                <div id="back-to-home-referrals" onClick={() => navigate("/")}>
                    <i className="pi pi-home"></i>
                    <div id="label">Back to Dashboard</div>
                </div>
            </div>}
            <div className="right">
                <Menu model={items} popup ref={menu} />
                <div className="name" onClick={(event) => menu.current.toggle(event)}>{ props.user.name }</div>
                <div className="profile-icon" onClick={(event) => menu.current.toggle(event)}></div>
            </div>
        </div>
    </div>
}