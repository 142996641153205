import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReferralService } from '../../services/ReferralService';
import { PaymentService } from '../../services/PaymentService';

export default function ReferralsWidget(props){

    const navigate = useNavigate();

    // const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [amount, setAmount] = useState(0);
    const [referralCount, setReferralCount] = useState(0);

    const goToReferrals = () => {
        navigate("/referrals");
    }

    useEffect(() => {        
        const loadData = async () => {
            const paymentService = new PaymentService();
            const paymentList = await paymentService.list();
            setPayments(paymentList);
            setAmount(ReferralService.getTotalAmountPerType(props.user.referrals, payments, ReferralService.PaymentOptions.cash));
            setReferralCount(ReferralService.getRefferalCount(props.user));
            // setLoading(false);
        }
        
        loadData();
    }, []);
    
    return <>
        <div className="widget referral-program">
            <div className="body">
                <div className="title">Referral Program</div>
                <div className="sub-title">Refer your friends, neighbors and family and earn cash.</div>
                <div className="card earned">
                    <div className="title">You <span>earned</span></div>
                    <div className="body">
                        <div className="value">${amount}</div>
                        <div className="icon"></div>
                    </div>
                </div>
                <div className="card referred">
                    <div className="title">You <span>referred</span></div>
                    <div className="body">
                        <div className="value">{referralCount}</div>
                        <div className="icon"></div>
                    </div>
                </div>
            </div>
            <div className="footer">               
                <div className="label" onClick={goToReferrals}>{ referralCount === 0 ? "SUBMIT YOUR REFERRALS HERE" : "SEE YOUR REFERRALS"} →</div>    
            </div>
        </div>
    </>;
}