import { useNavigate } from 'react-router-dom';
import { SolarTrackerService } from '../../services/SolarTrackerService';

import './css/desktop.css';
import './css/mobile.css';

export default function Footer(props) {

    const navigate = useNavigate();

    const solarTrackerService = new SolarTrackerService(props.user);

    return <div className="footer">
        <div className="links-container">
            <div className="links">
                <div className="link" onClick={() => window.open("https://www.poweredbyelevation.com/refer-a-friend")}>REFERRAL PROGRAM</div>
                { 
                    solarTrackerService.hasSolar() && 
                    <>
                    <div className="divider">|</div>
                    <div className="link" onClick={() => navigate("/tracker")}>SOLAR TRACKER</div>    
                    </>
                }            
                <div className="divider">|</div>
                <div className="link" onClick={() => window.open("https://energycurb.com")}>CURB</div>
                <div className="divider">|</div>
            </div>
            <div className="links">
                <div className="link" onClick={() => window.open("https://www.poweredbyelevation.com/energy-efficiency")}>ENERGY EFFICIENCY</div>
                <div className="divider">|</div>
                <div className="link" onClick={(e) => { window.location.href = "mailto: hello@poweredbyelevation.com"; e.preventDefault();}}>WRITE A REVIEW</div>
                <div className="divider">|</div>
                <div className="link" onClick={(e) => { window.location.href = "mailto: hello@poweredbyelevation.com"; e.preventDefault();}}>SUPPORT</div>
            </div>
        </div>
        <div className="review" onClick={(e) => { window.location.href = "mailto: hello@poweredbyelevation.com"; e.preventDefault();}}>
            <div className="title">Write a Review</div>
            <div className="sub-title">for a chance to win <span>$50 Amazon Gift Card!</span></div>
        </div>
    </div>
}