import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

import * as moment from 'moment';

import { ReferralService } from '../../services/ReferralService';
import { PaymentService } from '../../services/PaymentService';
import Header from '../../shared-components/Header/Header';
import Tabs from './components/Tabs/Tabs';
import Earnings from './components/Earnings/Earnings';
import AddReferral from './components/AddReferral';

import './css/desktop.css';
import './css/13inch.css';
import './css/mobile.css';

import amplitude from 'amplitude-js';
import config from '../../config.json';

export function ReferralsHome(props) {

    amplitude.getInstance().init(config.AMPLITUDE_KEY);

    const toast = useRef(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [referrals, setReferrals] = useState(props.user.referrals || []);
    const [payments, setPayments] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [showNewReferral, setShowNewReferral] = useState(false);
    const [visibleItems, setVisibleItems] = useState({});
    
    useEffect(() => {        
        amplitude.getInstance().logEvent("Referrals Screen Loaded");
        const loadData = async () => {
            const paymentService = new PaymentService();
            const paymentList = await paymentService.list();
            setPayments(paymentList);
            setLoading(false);
        }

        loadData();
        
    }, []);

    const add = (referral) => {
        const _user = props.user;
        _user.referrals.push(referral);
        props.onUserUpdate(_user);
        setShowNewReferral(false);
    }

    const showItem = (id) => {
        const _visibleItems = Object.assign({}, visibleItems);
        _visibleItems[id] = _visibleItems[id] ? false : true;
        setVisibleItems(_visibleItems)
    }

    const setPaymentOption = async (referral, option) => {
        try 
        {
            toast.current.clear();
            const paymentConfig = (props.user.paymentConfig || {}).config || {};
            if(option === "cash" && (paymentConfig.preferred || "") === ""){
                toast.current.show({severity: 'warn', summary: 'Pending Configuration', detail: 'To choose Cash as payment method you first need to configure it going to "Payment Options" > "Get Paid" > "Select Payment Method', life: 10000});
            }
            else if(option === "donation" && !paymentConfig.school){
                toast.current.show({severity: 'warn', summary: 'Pending Configuration', detail: 'To choose Donation as payment method you first need to configure it going to "Payment Options" > "Donate" > "Select Public School', life: 10000});
            }
            else {
                setSaving(true);
                const _user = props.user;
                const service = new ReferralService();
                _user.referrals = await service.updateReferralPaymentOption(referral.id, option);
                setSaving(false);
                props.onUserUpdate(_user);
            }
            
        } 
        catch (error) 
        {
            setSaving(false);
            console.log(error);
            alert(error.message ? error.message : error);
        }
    }

    const list = window.innerWidth > 1000 ? (referrals || []).slice((page - 1)*pageSize, page*pageSize) : referrals || [];

    const formatMobile = (mobile) => {
        const cleanedMobile = mobile.replace(/\D/g,'');
        if(cleanedMobile.length === 10){
            return `(${cleanedMobile.substring(0, 3)}) ${cleanedMobile.substring(3, 6)}-${cleanedMobile.substring(6, 10)}`;
        }
        
        return mobile;
    }

    return <>
    <div className="screen internal home">
        <Toast ref={toast}  position="top-left"/>
        <Header user={props.user} onLogout={props.onLogout} showBack={true}/>
        <div id="main">
        
        { (referrals || []).length === 0 && <>
            <div className="screen-segment left empty-list">
                <Earnings user={props.user} payments={payments}/>
                <div className="subtitle">
                    Welcome to Elevation Referral Program.<br/>
                    Looks like <span>you don’t have any referrals yet</span>.<br/> 
                    Click the button below to start adding some!
                </div>
                <div className="buttons">    
                    <Button disabled={saving} label="Add Referrals" className="button" onClick={() => setShowNewReferral(true)} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>
        </>}
        { 
            (referrals || []).length !== 0 && 
            <>
            <div className="screen-segment left">
                <Earnings user={props.user} payments={payments}/>
                <div className="title">Your Referrals</div> 
                <div className="subtitle">View the status of your referrals and share Elevation with more friends.</div>
                <div className="referral-list desktop">
                    <div className="column-header" style={{width: '20%'}}>Name</div>
                    <div className="column-header" style={{width: '20%'}}>Mobile #</div>
                    <div className="column-header" style={{width: '26%'}}>E-mail</div>
                    <div className="column-header center" style={{width: '12%'}}>App.<br/>Status</div>
                    <div className="column-header center" style={{width: '12%'}}>Acti.<br/>Status</div>
                    <div className="column-header center" style={{width: '10%'}}>Date</div>
                </div>
                { list.map(referral => <div  key={referral.id}>
                    <div className="referral-list-item desktop">
                        <div className="columns-row">
                            <div className="column-value" style={{width: '20%'}}>{referral.first_name} {referral.last_name}</div>
                            <div className="column-value" style={{width: '20%'}}>{formatMobile(referral.mobile)}</div>
                            <div className="column-value" style={{width: '26%'}}>{referral.email}</div>
                            <div className="column-value center" style={{width: '12%'}}>{ ReferralService.getAppointmentStatus(referral, payments) }</div>
                            <div className="column-value center" style={{width: '12%'}}>{ ReferralService.getActivationStatus(referral, payments) }</div>
                            <div className="column-value center grey" style={{width: '10%'}}>{moment(referral.created_on).format("MM/DD/YY")}</div>
                        </div>
                        { false && <div className="payments-row">
                            { false && ReferralService.hasPaidCash(referral, payments) &&
                                <div className="payment-column paid-cash">
                                    <img alt="Cash" src="/assets/layout/images/icon-cash.png"/>
                                    <span>Paid cash (${ReferralService.getPaidCash(referral, payments)})</span>
                                </div>
                            }
                            { false && ReferralService.hasPaidDonation(referral, payments) &&
                            <div className="payment-column paid-donation">
                                <img alt="Cash" src="/assets/layout/images/icon-donation.png"/>
                                <span>Donated (${ReferralService.getPaidDonation(referral, payments)})</span>
                            </div>
                            }
                            <div className="payment-column option-cash">
                                <img alt="Cash" src="/assets/layout/images/icon-cash.png"/>
                                <input disabled={saving || ReferralService.isPaymentMethodLocked(referral, payments)} type="radio" name={`payment-option-${referral.id}`} value="Cash" checked={((referral.payment_config || {}).option || "") === "cash"} onClick={() => setPaymentOption(referral, "cash")}/>
                                <span>Cash</span>
                            </div>
                            <div className="payment-column option-donation">
                                <img alt="Cash" src="/assets/layout/images/icon-donation.png"/>
                                <input disabled={saving || ReferralService.isPaymentMethodLocked(referral, payments)} type="radio" name={`payment-option-${referral.id}`} value="Donation"  checked={((referral.payment_config || {}).option || "") === "donation"} onClick={() => setPaymentOption(referral, "donation")}/>
                                <span>Donation</span>
                            </div>
                        </div> }
                    </div>
                    <div className="referral-list-item mobile">
                        <div className="item-header-row" onClick={() => showItem(referral.id)}>
                            <div className="header-row-title">
                                <span>{referral.first_name} {referral.last_name}</span>
                                <span className="completed">{ ReferralService.getMainStatus(referral, payments) }</span>
                            </div>
                            <div className="header-row-icon">
                                <i className="pi pi-angle-down"></i>
                            </div>
                        </div>
                        { visibleItems[referral.id] && <>
                            <div className="item-body-row">
                                <div className="item-body-row-line">
                                    <div className="row-title">Mobile #</div>
                                    <div className="row-value">{formatMobile(referral.mobile)}</div>
                                </div>
                                <div className="item-body-row-line">
                                    <div className="row-title">E-Mail</div>
                                    <div className="row-value">{referral.email}</div>
                                </div>
                                <div className="item-body-row-line">
                                    <div className="row-title">App. Status</div>
                                    <div className="row-value">{ ReferralService.getAppointmentStatus(referral, payments) }</div>
                                </div>
                                <div className="item-body-row-line">
                                    <div className="row-title">Act. Status</div>
                                    <div className="row-value">{ ReferralService.getActivationStatus(referral, payments) }</div>
                                </div>
                                <div className="item-body-row-line">
                                    <div className="row-title">Date</div>
                                    <div className="row-value">{moment(referral.created_on).format("MM/DD/YY")}</div>
                                </div>
                            </div>
                            { false && <div className="payments-row">
                                { false && ReferralService.hasPaidCash(referral, payments) &&
                                    <div className="payment-column paid-cash">
                                        <img alt="Cash" src="/assets/layout/images/icon-cash.png"/>
                                        <span>Paid cash (${ReferralService.getPaidCash(referral, payments)})</span>
                                    </div>
                                }
                                { false && ReferralService.hasPaidDonation(referral, payments) &&
                                <div className="payment-column paid-donation">
                                    <img alt="Cash" src="/assets/layout/images/icon-donation.png"/>
                                    <span>Donated (${ReferralService.getPaidDonation(referral, payments)})</span>
                                </div>
                                }
                                <div className="payment-column option-cash">
                                    <img alt="Cash" src="/assets/layout/images/icon-cash.png"/>
                                    <input disabled={saving} type="radio" name={`payment-option-${referral.id}`} value="Cash" checked={((referral.payment_config || {}).option || "") === "cash"} onClick={() => setPaymentOption(referral, "cash")}/>
                                    <span>Cash</span>
                                </div>
                                <div className="payment-column option-donation">
                                    <img alt="Cash" src="/assets/layout/images/icon-donation.png"/>
                                    <input disabled={saving} type="radio" name={`payment-option-${referral.id}`} value="Donation"  checked={((referral.payment_config || {}).option || "") === "donation"} onClick={() => setPaymentOption(referral, "donation")}/>
                                    <span>Donation</span>
                                </div>
                            </div>}
                        </>}
                    </div>
                    </div>
                )}
                { (saving || loading) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                { (referrals || []).length > 4 &&
                <div className="pagination">
                    <div className="page-size">
                        <div className="label">Show</div>
                        <div className="field"><Dropdown disabled={saving} value={pageSize} options={[4, 8, 16, 32]} onChange={(e) => setPageSize(e.value)}/></div>
                    </div>
                    { Math.ceil(referrals.length/pageSize) > 1 &&
                    <div className="page-list">
                        { page !== 1 && 
                            <>
                                <div className="page" onClick={() => setPage(page - 1)}>Previous Page</div>
                                <span className="divider">|</span>
                            </>
                        }
                        { (new Array(Math.ceil(referrals.length/pageSize))).fill(null).map((x,i) => <div key={`page_${i}`} className={`page${i+1===page? " current" : ""}`} onClick={() => setPage(i + 1)}>{i + 1}</div>)}
                        { page !== Math.ceil(referrals.length/pageSize) && 
                            <>
                                <span className="divider">|</span>
                                <div className="page" onClick={() => setPage(page + 1)}>Next Page</div>
                            </>
                        }
                    </div>}
                </div>
                }
                <div className="buttons">    
                    <Button disabled={saving} label="Add More Referrals" className="button" onClick={() => setShowNewReferral(true)} />         
                </div>
                
            </div>
            </>
        }
        
        <div className="screen-segment home right">
            <Tabs user={props.user} onUserUpdate={props.onUserUpdate}  payments={payments}/>
        </div>
        { showNewReferral && <>
            <AddReferral referrals={referrals} onReferralCreated={add} onHide={() => setShowNewReferral(false)}/>
        </>}
        </div>
    </div>
    </>
}