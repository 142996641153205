// React Imports
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// PrimeReact imports
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';

// Css imports
import './css/desktop.css';
import './css/mobile.css';

//Services imports
import { AuthorizationService } from '../../services/AuthorizationService';

import amplitude from 'amplitude-js';
import config from '../../config.json';

export default function LoginScreen(props)
{
    amplitude.getInstance().init(config.AMPLITUDE_KEY);

    useEffect(()=>{
        amplitude.getInstance().logEvent("Signin Screen Loaded");
    },[]);

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showWrongCredentialsMessage, setShowWrongCredentialsMessage] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);

    const [showPasswordRecover, setShowPasswordRecover] = useState(false);
    
    const [sendingCode, setSendingCode] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [checkingCode, setCheckingCode] = useState(false);
    const [codeChecked, setCodeChecked] = useState(false);
    
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [savingPassword, setSavingPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordReset, setPasswordReset] = useState(false);

    const goToSignUp = () => {
        navigate("/sign-up");
    }

    const login = async () => 
    {
        try 
        {
            setAuthenticating(true);
            setShowWrongCredentialsMessage(false);
            const authorizationService = new AuthorizationService();
            const user = await authorizationService.login(username, password);
            props.onAuthenticated(user);
            setAuthenticating(false);
        } 
        catch (error) 
        {
            setAuthenticating(false);
            console.log(error);
            if(error.code && error.code === 401){
                setShowWrongCredentialsMessage(true);
            }
            else {
                alert(error.code ? error.code + " - " + error.message : error);
            }            
        }
    }

    const backToLogin = () => {
        setCode("");
        setCodeError(false)
        setCodeSent(false);
        setCodeChecked(false);
        setPasswordConfirmation("");
        setPasswordError(null);
        setShowPasswordRecover(false);
        setPassword("");
        setShowWrongCredentialsMessage(false);
    }

    const sendCode = async () => {
        try {
            setCode("");
            setCodeError(false);
            setSendingCode(true);
            const service = new AuthorizationService();
            await service.sendToken(username);
            setSendingCode(false);
            setCodeSent(true);    
        } 
        catch (error) 
        {
            setSendingCode(false);
            setCodeSent(false);    
            console.log(error);
            window.alert(error.message || error);
        }
        
    }

    const checkCode = async () => {
        
        try {
            setCodeError(false);
            setCheckingCode(true);
            const service = new AuthorizationService();
            await service.checkToken(code);
            setCheckingCode(false);
            setCodeChecked(true); 
        } 
        catch (error) 
        {
            console.log(error);
            setCheckingCode(false);
            setCodeChecked(false);    
            if(error.code && error.code === 400){
                setCodeError(true);
            }
            else {
                window.alert(error.message || error);
            }
        }
    }

    const resetPassword = async () => {
        setPasswordError(null)
        if(password !== passwordConfirmation){
            setPasswordError("Password confirmation does not match")
        }
        else {
            try {
                setSavingPassword(true);
                const service = new AuthorizationService();
                await service.resetPassword(password);
                setSavingPassword(false);
                setPasswordReset(true);
            } 
            catch (error) 
            {
                console.log(error);
                setSavingPassword(false);
                if(error.code && error.code === 400){
                    setPasswordError("Password is not complex enough. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters.");
                }
                else {
                    window.alert(error.message || error);
                }
            }
        }
    }

    return <div className="screen external login">
        <div id="main">
            <div id="left">
                <div id="logo"></div>
                { !showPasswordRecover && <>
                    <div id="title">
                        Welcome to<br/>
                        Elevation<br/>
                        Customer Portal
                    </div>
                    <div id="form">
                        <div className="field">
                            <InputText type="text" value={username} onChange={(e) => setUsername(e.target.value)}  placeholder="E-mail"/>
                        </div>
                        <div className="field">
                            <Password feedback={false} toggleMask={true} value={password} onChange={(e) => setPassword(e.target.value)}  placeholder="Password"/>
                        </div>
                        <div id="login-button" className='button' onClick={login} style={ username === "" || password === "" ? { opacity: 0.5, cursor: 'default'} : {}}></div>
                        { (authenticating) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                        { 
                            showWrongCredentialsMessage && 
                            <div className="error-message">Wrong credentials.</div>
                        }
                        <div id="links">
                            <div className="link-item">
                                <span className="icon">→</span>
                                <span className="label">Don't have an account?</span>
                                <span className="link" onClick={goToSignUp}>Sign up.</span>
                            </div>
                            <div className="link-item">
                                <span className="icon">→</span>
                                <span className="label">Forgot your password?</span>
                                <span className="link" onClick={() => setShowPasswordRecover(true)}>Retreive it.</span>
                            </div>
                        </div>
                    </div>
                    </>
                }
                { showPasswordRecover && !codeSent &&
                    <>
                    <div id="title">What is your e-mail address?</div>
                    <div id="subtitle">We will send it a verification code.</div>
                    <div id="form">
                        <div className="field">
                            <InputText type="text" value={username} onChange={(e) => setUsername(e.target.value)}  placeholder="Your E-mail Address"/>
                        </div>
                        <Button disabled={sendingCode || username === ""} label="Send verification code" className="button" onClick={sendCode} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    { sendingCode && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    <div id="links">
                        <div className="link-item">
                            <span className="icon">→</span>
                            <span className="label">Have you remembered your password?</span>
                            <span className="link" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </div>
                    </>
                }
                { showPasswordRecover && codeSent && !codeChecked &&
                    <>
                    <div id="title">We've sent you a verification code</div>
                    <div id="subtitle">Please check you inbox, fill the received code on the field below and click "Verify".</div>
                    <div id="form">
                        <div className="field">
                            <InputText type="text" value={code} onChange={(e) => setCode(e.target.value)}  placeholder="Verification Code"/>
                        </div>
                        { codeError && <div className="error-message">Invalid verification code</div> }
                        <Button disabled={sendingCode || checkingCode} label="Verify" className="button" onClick={checkCode} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    { (sendingCode || checkingCode) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    <div id="links">
                        <div className="link-item">
                            <span className="icon">→</span>
                            <span className="label">Didn't get the code?</span>
                            <span className="link" onClick={() => sendCode()}>Send it again</span>.
                        </div>
                        <div className="link-item">
                            <span className="icon">→</span>
                            <span className="label">Have you remembered your password?</span>
                            <span className="link" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </div>
                    </>
                }
                { showPasswordRecover && codeChecked && !passwordReset && 
                    <>
                    <div id="title">Verification successful</div>
                    <div id="subtitle">Please create your new password.</div>
                    <div id="form">
                        <div className="field">
                            <Password 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder="Your Password"
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                                promptLabel="Password must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                weakLabel="Password is too weak. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                mediumLabel="Password is not complex enough yet. It must have at least 8 characters, contain letters and numbers and both lower and uppercase letters."
                                strongLabel="We are good to go."
                                toggleMask={true}
                            />
                        </div>
                        <div className="field">
                            <Password 
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                placeholder="Confirm It" 
                                feedback={false}
                                toggleMask={true}
                                style={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                            />

                        </div>
                        { passwordError && <div className="error-message">{passwordError}</div> }
                        <Button disabled={savingPassword} label="Save" className="button" onClick={resetPassword} icon="pi pi-arrow-right" iconPos="right"/>
                    </div>
                    { (savingPassword) && <ProgressBar mode="indeterminate"  style={{ height: '6px' }} color="#0052CB" />}
                    <div id="links">
                        <div className="link-item">
                            <span className="icon">→</span>
                            <span className="label">Have you remembered your password?</span>
                            <span className="link" onClick={() => backToLogin()}>Back to Login</span>.
                        </div>
                    </div>
                    </>
                }
                { showPasswordRecover && passwordReset && 
                    <>
                        <div id="title">Password reset successfully.</div>
                        <div id="form">    
                            <Button label="Back to Login" className="button" onClick={backToLogin} icon="pi pi-arrow-right" iconPos="right"/>
                        </div>
                    </>
                }
            </div>
            <div id="right"></div>
        </div>
    </div>
}