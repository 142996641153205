import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import VideoPlayer from '../../shared-components/VideoPlayer/VideoPlayer';
import AddToElevatedHomeWidget from './AddToElevatedHomeWidget';

import { Toast } from 'primereact/toast';

export default function TrackerWidget(props) {

    const navigate = useNavigate();
    const solarTrackerService = props.service;
    const videoURL = "https://media.elevation-one.com/energy-consultation.mp4";

    const goToTracker = () => {
        navigate("/tracker");
    }

    const [showVideo, setShowVideo] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const [showAddToElevatedHomeWidget, setShowAddToElevatedHomeWidget] = useState(false);

    const toast = useRef(null);
    if(showAddToElevatedHomeWidget)
    {
        
        
        return <>
            <Toast ref={toast}  position="center"/>
            <AddToElevatedHomeWidget 
                type="Solar" 
                user={props.user} 
                videoURL={videoURL} 
                showWarnMessage={(message) => toast.current.show({severity: 'warn', summary: 'Warning', detail: message, life: 10000})}
                showErrorMessage={(message) => toast.current.show({severity: 'error', summary: 'Ooops... An error occurred', detail: message, sticky: true})}
                onFinish={() => {
                    toast.current.show({severity: 'success', summary: 'Success', detail: 'We will contact you soon', life: 10000});
                    setShowAddToElevatedHomeWidget(false)}
                }
                action="CreateLead"
                leadSource="Marketing"
                subLeadSource="Customer Portal"
            />
        </>
    }

    return <>
        <Toast ref={toast}  position="center"/>
        { !solarTrackerService.isSolarElevated() &&
            <div className="widget solar">
                <div className="body">
                    <div className="title">Solar</div>
                    { solarTrackerService.hasSolar() && 
                        <>
                        <div className="sub-title">Track the status of your solar energy installation and learn what to expect.</div>
                        <div id="step-description">
                            <div className="gradient"></div>
                            <div className="bg"></div>
                            <div className="info">
                                <div className="title">
                                    <div className="label">Step</div>
                                    <div className="value">{ solarTrackerService.getCurrentStep().index } of { solarTrackerService.getSteps().length }</div>
                                </div>
                                <div className="progress-bar">
                                    <div className="value" style={{width: `${solarTrackerService.getSolarPercentage()}%`}}></div>
                                </div>
                                <div className="name">{ solarTrackerService.getCurrentStep().label }</div>
                                <div className="time-icon">
                                    <i className="pi pi-clock"></i>
                                </div>
                                <div className="time-value">{ solarTrackerService.getCurrentStep().duration }</div>
                            </div>
                        </div>
                        </>
                    }
                    { !solarTrackerService.hasSolar() && 
                        <>
                        <div className="sub-title">Go Solar with Elevation, save big on your energy bill and help the planet!</div>
                        {
                            showForm && <>
                            
                            </>
                        }
                        {
                            !showForm && 
                            <>
                                <div className='cover-image' onClick={() => setShowVideo(true)}>
                                    <div className='player-button' onClick={() => { setShowVideo(true); }}></div>
                                </div>
                                <VideoPlayer 
                                    showVideo={showVideo} 
                                    label="Solar"
                                    videoURL={videoURL}
                                    onCloseVideo={() => setShowVideo(false)}
                                />
                            </>
                        }
                        </>
                    }
                </div>
                <div className="footer dark">
                    { solarTrackerService.hasSolar() && <div className="label" onClick={goToTracker}>SEE THE ENTIRE PROCESS →</div>}
                    { !solarTrackerService.hasSolar() && <>
                        <div className="icon" onClick={() => setShowAddToElevatedHomeWidget(true)}></div>
                        <div className="label" onClick={() => setShowAddToElevatedHomeWidget(true)}>ADD TO YOUR ELEVATED HOME</div>
                    </>}
                </div>
            </div>
        }
    </>;
}