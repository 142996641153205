import { BaseService } from './_BaseService';

export class SolarTrackerService extends BaseService {

    constructor(user){
        super();
        this.user = user;

        this.steps = [
            { 
                index: 1,
                label: "Home Assessment", 
                duration: "~ 2 days",
                whatIsThis: "Your Elevated Home is custom designed to your needs, so it is important that Elevation assesses the size, condition, and characteristics   of both your home's roof and electrical panel to determine the best design options for you. Elevation solar systems are built to last 25 years or more and should be installed only when the home is solar ready.",
                whatShoudYouExpect: "Our concierge team will call to schedule a Site Survey and one of our experienced technicians will visit your home for about an hour to ensure your design is feasible. You do not have to be present during the visit as long as we have access to the exterior of your home; our visit will be quick and unintrusive. ",
                whatCanYouDo: "You can help us by sharing the age and any known conditions of your roof and electrical panel. Also, try and be flexible in your calendar - the quicker the assessment can be completed, the quicker we can present to you a design for your approval.",
                nextStepName: "Design and Engineering",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/home-assessment.png",
                        videoURL: "https://media.elevation-one.com/home-assessment.mp4",
                    }
                ],
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            },
            { 
                index: 2,
                label: "Design and Engineering", 
                duration: "~ 1 week",
                whatIsThis: "A team of experienced designers along with your Energy Advisor will put together a preliminary design for your Elevated Home. In this stage, all aspects of the design are carefully inspected – electrical design, mechanical design, and environmental design - plus compliance with all relevant federal, state, and local laws and guidelines are assured.",
                whatShoudYouExpect: "You should expect an Elevated Home designed to meet YOUR needs. The preliminary design as well as any modifications based on home conditions or compliance guidelines will be shared with you for final approval.",
                whatCanYouDo: "Share your input! Any questions, special considerations, or system preferences need to be satisfied before moving on. Elevation's Energy Consultants and Designers are some of the best in the industry, and your collaboration is essential to the design of your Elevated Home.",
                nextStepName: "Permits and Materials",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/design-engineering.png",
                        videoURL: "https://media.elevation-one.com/design-engineering.mp4",
                    }
                ],
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            },
            { 
                index: 3,
                label: "Permits and Approvals", 
                duration: "~ 4-8 weeks",
                whatIsThis: "Your Municipality has to be informed of your new Elevated Home design. Since no two system designs are the same, your local utility company will review your solar system to ensure all structural and electrical codes are satisfied before giving approval. You can rely on your custom designed system and be confident it will produce the energy to satisfy your needs.",
                whatShoudYouExpect: "Determining install readiness of a solar design requires a 3-way partnership between Elevation, your Municipality, and your local utility company. It is normal for one of the parties in the partnership to ask for adjustments to the electrical design, resulting in more than one iteration of review. The time it takes to obtain approvals varies by municipality and can take several months, but the critical priority in all areas is the same: To ensure all parties agree the system will be safe and productive.",
                whatCanYouDo: "Utilize your resources to track your job's status. Your best option is to check in here - your solar tracker - for updates. Our service team will also communicate status updates as your project progresses. ",
                nextStepName: "Installation",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/finalprojectplan.png",
                        videoURL: "https://media.elevation-one.com/finalprojectplan.mp4",
                        title: "Your Final Project Plan"
                    },
                    {
                        videoThumbURL: "https://media.elevation-one.com/permits-approvals.png",
                        videoURL: "https://media.elevation-one.com/permits-approvals.mp4",
                        title: "Permits and Approvals"
                    }
                ],
                
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            },
            { 
                index: 4,
                label: "Installation", 
                duration: "~ 2-8 weeks",
                whatIsThis: "The quality of your Elevated Home installation is crucial. A proper installation can give your home a lifespan of up to 25 years. At the scheduled time the Elevation installation team will arrive and begin to attach the panels to your roof, install and set up the inverter, and make the necessary modifications to your home's electrical system. After the install is complete, our team will conduct a quality control review to ensure accuracy.",
                whatShoudYouExpect: "Our teams are extremely professional, experienced, and efficient. You should expect the entire installation to be completed within a few hours and in most cases, you don’t need to be home.",
                whatCanYouDo: "Multiple visits to complete an install is costly and time-intensive. You can do a few simple things to help us complete the install on schedule: Provide the install team with Wifi to connect your system, provide gate codes if your home is in a secured neighborhood, and kennel dogs or other pets that may get in the way of install.",
                nextStepName: "Inspections",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/installation.png",
                        videoURL: "https://media.elevation-one.com/installation.mp4",
                    }
                ],
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            },
            { 
                index: 5,
                label: "Inspections", 
                duration: "~ 4-8 weeks",
                whatIsThis: "Just as your municipality needed to approve of your installation, they need to inspect our work to ensure the approved plans were followed. After inspections, your local utility will swap out your electrical meter and give permission to operate (PTO).",
                whatShoudYouExpect: "The critical focus continues to be safety and system productivity. As a result, these inspections are thorough and may require small corrections and re-inspections before passing. You can be confident that once your system passes all inspections, your home is ready to be fully Elevated!",
                whatCanYouDo: "As we strive for perfection, we ask for your patience, modifications and re-inspections can be required by your local building department.",
                nextStepName: "Activation",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/inspections.png",
                        videoURL: "https://media.elevation-one.com/inspections.mp4",
                    }
                ],
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            },
            {  
                index: 6,
                label: "Activation", 
                duration: "~ 4 weeks" ,
                whatIsThis: "Congratulations - your Elevated Home is now fully activated! Your solar system is converting sunlight into electricity and your smart energy technology is tracking your usage in detail.",
                whatShoudYouExpect: "Projected savings can take time. Your home's energy usage varies; in addition, electric company billing systems regularly use monthly averages and estimates instead of actual meter readings. For these reasons, it can often take several months for energy bills to fully reflect the savings produced by your Elevated Home.",
                whatCanYouDo: "Other than monitor your system's production and your home's energy usage, get a yearly energy audit and enjoy your Elevated Home!",
                nextStepName: "Elevated Home",
                nextStepShortDescritpion: "",
                videos: [
                    {
                        videoThumbURL: "https://media.elevation-one.com/activation.png",
                        videoURL: "https://media.elevation-one.com/activation.mp4",
                    }
                ],
                dashboardSubtitle: "Soon, you will have a <span>100% Elevated Home!</span> Track installation process for Solar, Curb, and get ready for your efficiency assessment."
            }
        ];

        this.steps.forEach(step => {
            step.complete = step.index < user.app_data?.solar?.index;
            step.current = step.index === user.app_data?.solar?.index;
        });
    }

    getCurrentStep = () => {
        return this.steps.find(s => s.current) || {};
    }

    getSteps = () => {
        return this.steps;
    }

    isSolarElevated = () => {
        return this.user.app_data?.solar?.elevated;
    }

    hasSolar = () => {
        return (this.user.app_data?.solar?.index || 0) > 0;
    }

    hasEE = () => {
        return (this.user.app_data?.ee?.index || 0) > 0;
    }

    isEEElevated = () => {
        return this.user.app_data?.ee?.elevated;
    }

    getEEInstallationDate = () => {
        return (this.user.app_data?.ee?.installationDate || "");
    }

    isEEToBeInstalled = () => {
        return  this.getEEInstallationDate() !== "";
    }

    
    isCurbElevated = () => {
        return this.user.app_data?.curb?.elevated;
    }

    isCurbToBeInstalled = () => {
        return this.getCurbInstallationDate() !== "";
    }

    hasCurb = () => {
        return (this.user.app_data?.curb?.index || 0) > 0;
    }

    getCurbInstallationDate = () => {
        return (this.user.app_data?.curb?.installationDate || "");
    }

    
    getDashboardSubtitle = () => {
        return this.isSolarElevated() ? "Your home is <span>100% elevated!</span> You produce energy, get control of your consumption, save money and protect the environment."
            : this.hasSolar() ? this.getCurrentStep().dashboardSubtitle
            : "You haven’t joined the Elevated Home club yet. Start today with learning about how Solar, Curb and Energy Efficiency can help you.";
    }

    getSolarPercentage = () => {
        return this.isSolarElevated() ? 100
            : this.hasSolar() ? Math.round(100*(this.getCurrentStep().index - 1)/this.steps.length)
            : 0;
    }

    getElevatedHomePercentage = () => {
        return parseInt(this.getSolarPercentage()/2,10) +
            (this.isCurbElevated() ? 25 : 0) + 
            (this.isEEElevated() ? 25 : 0);
    }
}